import React from 'react'
import PropTypes from 'prop-types'

const sqmToSqft = sqm => {
  return Number.parseInt(sqm * 10.764)
}

const pricePerSqft = (price, sqft) => {
  return Number.parseInt(price / sqft)
}

function numberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const TransactionItem = (
  { transaction:
    { block,
      street_name,
      town,
      resale_price,
      flat_type,
      flat_model,
      storey_range,
      floor_area_sqm,
      lease_commence_date,
      remaining_lease,
      month
    }
  }) => {

  return (
    <div class="mb-2 col-sm-12 col-md-6 col-xl-4">
      <div class="card">
        <div class="card-header text-white text-center bg-primary  ">
          {block} {street_name} {town}
        </div>
        <div class="card-body p-2">
          <div class="row">
            <div class="col">
              <p class="mb-0">{flat_type} {flat_model}</p>
              <p class="mb-0">Storey: {storey_range}</p>
              <p class="mb-0">{floor_area_sqm} sqm ({sqmToSqft(floor_area_sqm)} sqft)</p>
              <p class="mb-0">Built: {lease_commence_date}</p>
              <p class="mb-0">Remaining Lease: {remaining_lease}</p>
            </div>
            <div class="col-auto d-flex align-items-end flex-column">
              <h2 class="card-text text-right mb-0">{numberWithCommas(resale_price)}</h2>
              <p class="mb-0 text-right">${pricePerSqft(resale_price, sqmToSqft(floor_area_sqm))} psf</p>
              <p class="mb-0 date">Date:{month}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

TransactionItem.propTypes = {
  transaction: PropTypes.object.isRequired,
}
export default TransactionItem
