import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Alert from './components/layout/Alert';
import NotFound from './components/pages/NotFound';
import Home from './components/pages/Home';

import TransactionServiceState from './context/transaction/TransactionServiceState';
import AlertState from './context/alert/AlertState';

import './App.css';
import './custom.scss';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDLuriKkACVk1SoEG_OfMbd7mMXxjiBMiQ",
  authDomain: "hdb-transaction.firebaseapp.com",
  databaseURL: "https://hdb-transaction.firebaseio.com",
  projectId: "hdb-transaction",
  storageBucket: "hdb-transaction.appspot.com",
  messagingSenderId: "183631543688",
  appId: "1:183631543688:web:31c06c53250aa5cbcfce3d",
  measurementId: "G-PFGG6433WR"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = () => {
  return (
    <TransactionServiceState>
      <AlertState>
        <Router>
          <div>
            <Navbar title="HDB Resale Transactions" />
            <div className="container">
              <Alert />
              <Switch>
                <Route exact path="/" component={Home} />
                {/* <Route exact path="/user/:login" component={User} />
                <Route exact path="/about" component={About} />
                <Route path="/about" component={NotFound} /> */}
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </AlertState>
    </TransactionServiceState>
  );
};

export default App;
