import React, { useContext } from 'react'
import TransactionItem from './TransactionItem'
import Spinner from '../layout/Spinner'
import TransactionServiceContext from '../../context/transaction/TransactionServiceContext'

const Transactions = () => {

  const gc = useContext(TransactionServiceContext);

  const { loading, transactions, error, success } = gc;

  function getTransactions() {
    if (transactions && success) {
      if (transactions.length > 0) {
        return transactions.map(transaction => (
          <TransactionItem key={transaction._id} transaction={transaction} />
        ))
      } else {
        return noResult
      }

    } else {
      return initialPlaceholder
    }
  }

  function getError() {
    return (
      <div className="col m-4 text-center text-black">
        <p>Ops something wrong...</p>
        <p>The data source server could be under maintenance now</p>
        <p>Sorry for the inconvenience</p>
      </div>
    )
  }

  const initialPlaceholder = (
    <div className="col m-4 text-center text-black">
      <p>Tips:</p>
      <p>Try search with block or street name</p>
    </div>
  )

  const noResult = (
    <div className="col m-4 text-center text-black">
      <p>Ops.. no results found.</p>
      <p>Tips:</p>
      <p>1. Try use street name from the suggestion list for better results</p>
      <p>2. Some records are using abbreviation like Bt for Bukit, Jln for Jalan, Nth for North</p>
    </div>
  )

  if (loading) {
    return <Spinner />
  }
  else {
    return (
      <div className="row mt-2">
        {error ? getError() : getTransactions()}
      </div>
    )
  }
}

export default Transactions

