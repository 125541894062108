import React, { Fragment } from 'react'
const StreeNameList = () => {
    return (
        <Fragment>
            <datalist id="streetNames">
                <option>Admiralty Dr </option>
                <option>Admiralty Link </option>
                <option>Ah Hood Rd </option>
                <option>Aljunied Ave 2 </option>
                <option>Aljunied Cres </option>
                <option>Aljunied Rd </option>
                <option>Alkaff Cres </option>
                <option>Anchorvale Cres </option>
                <option>Anchorvale Dr </option>
                <option>Anchorvale Lane </option>
                <option>Anchorvale Link </option>
                <option>Anchorvale Rd </option>
                <option>Anchorvale St </option>
                <option>Ang Mo Kio Ave 1 </option>
                <option>Ang Mo Kio Ave 10 </option>
                <option>Ang Mo Kio Ave 2 </option>
                <option>Ang Mo Kio Ave 3 </option>
                <option>Ang Mo Kio Ave 4 </option>
                <option>Ang Mo Kio Ave 5 </option>
                <option>Ang Mo Kio Ave 6 </option>
                <option>Ang Mo Kio Ave 8 </option>
                <option>Ang Mo Kio Ave 9 </option>
                <option>Ang Mo Kio St 11 </option>
                <option>Ang Mo Kio St 21 </option>
                <option>Ang Mo Kio St 31 </option>
                <option>Ang Mo Kio St 32 </option>
                <option>Ang Mo Kio St 44 </option>
                <option>Ang Mo Kio St 51 </option>
                <option>Ang Mo Kio St 52 </option>
                <option>Ang Mo Kio St 61 </option>
                <option>Bain St </option>
                <option>Balam Rd </option>
                <option>Banda St </option>
                <option>Bangkit Rd </option>
                <option>Beach Rd </option>
                <option>Bedok Ctrl </option>
                <option>Bedok Nth Ave 1 </option>
                <option>Bedok Nth Ave 2 </option>
                <option>Bedok Nth Ave 3 </option>
                <option>Bedok Nth Ave 4 </option>
                <option>Bedok Nth Rd </option>
                <option>Bedok Nth St 1 </option>
                <option>Bedok Nth St 2 </option>
                <option>Bedok Nth St 3 </option>
                <option>Bedok Nth St 4 </option>
                <option>Bedok Reservoir Cres </option>
                <option>Bedok Reservoir Rd </option>
                <option>Bedok Reservoir View </option>
                <option>Bedok Sth Ave 1 </option>
                <option>Bedok Sth Ave 2 </option>
                <option>Bedok Sth Ave 3 </option>
                <option>Bedok Sth Rd </option>
                <option>Bendemeer Rd </option>
                <option>Beo Cres </option>
                <option>Bidadari Pk Dr </option>
                <option>Bishan St 11 </option>
                <option>Bishan St 12 </option>
                <option>Bishan St 13 </option>
                <option>Bishan St 22 </option>
                <option>Bishan St 23 </option>
                <option>Bishan St 24 </option>
                <option>Boon Keng Rd </option>
                <option>Boon Lay Ave </option>
                <option>Boon Lay Dr </option>
                <option>Boon Lay Pl </option>
                <option>Boon Tiong Rd </option>
                <option>Bright Hill Dr </option>
                <option>Bt Batok Ctrl </option>
                <option>Bt Batok East Ave 3 </option>
                <option>Bt Batok East Ave 4 </option>
                <option>Bt Batok East Ave 5 </option>
                <option>Bt Batok East Ave 6 </option>
                <option>Bt Batok St 11 </option>
                <option>Bt Batok St 21 </option>
                <option>Bt Batok St 22 </option>
                <option>Bt Batok St 24 </option>
                <option>Bt Batok St 25 </option>
                <option>Bt Batok St 31 </option>
                <option>Bt Batok St 32 </option>
                <option>Bt Batok St 33 </option>
                <option>Bt Batok St 34 </option>
                <option>Bt Batok St 41 </option>
                <option>Bt Batok St 51 </option>
                <option>Bt Batok St 52 </option>
                <option>Bt Batok West Ave 2 </option>
                <option>Bt Batok West Ave 4 </option>
                <option>Bt Batok West Ave 5 </option>
                <option>Bt Batok West Ave 6 </option>
                <option>Bt Batok West Ave 7 </option>
                <option>Bt Batok West Ave 8 </option>
                <option>Bt Batok West Ave 9 </option>
                <option>Bt Merah Ctrl </option>
                <option>Bt Merah Lane 1 </option>
                <option>Bt Merah View </option>
                <option>Bt Panjang Ring Rd </option>
                <option>Bt Purmei Rd </option>
                <option>Buangkok Cres </option>
                <option>Buangkok Green </option>
                <option>Buangkok Link </option>
                <option>Buffalo Rd </option>
                <option>C\'wealth Ave </option>
                <option>C\'wealth Ave West </option>
                <option>C\'wealth Cl </option>
                <option>C\'wealth Cres </option>
                <option>C\'wealth Dr </option>
                <option>Cambridge Rd </option>
                <option>Canberra Cres </option>
                <option>Canberra Link </option>
                <option>Canberra Rd </option>
                <option>Canberra St </option>
                <option>Canberra View </option>
                <option>Canberra Walk </option>
                <option>Cantonment Cl </option>
                <option>Cantonment Rd </option>
                <option>Cashew Rd </option>
                <option>Cassia Cres </option>
                <option>Chai Chee Ave </option>
                <option>Chai Chee Dr </option>
                <option>Chai Chee Rd </option>
                <option>Chai Chee St </option>
                <option>Champions Way </option>
                <option>Chander Rd </option>
                <option>Changi Village Rd </option>
                <option>Chay Yan St </option>
                <option>Chin Swee Rd </option>
                <option>Choa Chu Kang Ave 1 </option>
                <option>Choa Chu Kang Ave 2 </option>
                <option>Choa Chu Kang Ave 3 </option>
                <option>Choa Chu Kang Ave 4 </option>
                <option>Choa Chu Kang Ave 5 </option>
                <option>Choa Chu Kang Ave 7 </option>
                <option>Choa Chu Kang Cres </option>
                <option>Choa Chu Kang Ctrl </option>
                <option>Choa Chu Kang Dr </option>
                <option>Choa Chu Kang Loop </option>
                <option>Choa Chu Kang Nth 5 </option>
                <option>Choa Chu Kang Nth 6 </option>
                <option>Choa Chu Kang Nth 7 </option>
                <option>Choa Chu Kang St 51 </option>
                <option>Choa Chu Kang St 52 </option>
                <option>Choa Chu Kang St 53 </option>
                <option>Choa Chu Kang St 54 </option>
                <option>Choa Chu Kang St 62 </option>
                <option>Choa Chu Kang St 64 </option>
                <option>Circuit Rd </option>
                <option>Clarence Lane </option>
                <option>Clementi Ave 1 </option>
                <option>Clementi Ave 2 </option>
                <option>Clementi Ave 3 </option>
                <option>Clementi Ave 4 </option>
                <option>Clementi Ave 5 </option>
                <option>Clementi Ave 6 </option>
                <option>Clementi St 11 </option>
                <option>Clementi St 12 </option>
                <option>Clementi St 13 </option>
                <option>Clementi St 14 </option>
                <option>Clementi West St 1 </option>
                <option>Clementi West St 2 </option>
                <option>Compassvale Bow </option>
                <option>Compassvale Cres </option>
                <option>Compassvale Dr </option>
                <option>Compassvale Lane </option>
                <option>Compassvale Link </option>
                <option>Compassvale Rd </option>
                <option>Compassvale St </option>
                <option>Compassvale Walk </option>
                <option>Corporation Dr </option>
                <option>Crawford Lane </option>
                <option>Dakota Cres </option>
                <option>Dawson Rd </option>
                <option>Delta Ave </option>
                <option>Depot Rd </option>
                <option>Dorset Rd </option>
                <option>Dover Cl East </option>
                <option>Dover Cres </option>
                <option>Dover Rd </option>
                <option>Edgedale Plains </option>
                <option>Edgefield Plains </option>
                <option>Edgefield Walk </option>
                <option>Elias Rd </option>
                <option>Empress Rd </option>
                <option>Eng Hoon St </option>
                <option>Eng Watt St </option>
                <option>Eunos Cres </option>
                <option>Eunos Rd 5 </option>
                <option>Everton Pk </option>
                <option>Fajar Rd </option>
                <option>Farrer Pk Rd </option>
                <option>Farrer Rd </option>
                <option>Fernvale Lane </option>
                <option>Fernvale Link </option>
                <option>Fernvale Rd </option>
                <option>Fernvale St </option>
                <option>French Rd </option>
                <option>Gangsa Rd </option>
                <option>Geylang Bahru </option>
                <option>Geylang East Ave 1 </option>
                <option>Geylang East Ave 2 </option>
                <option>Geylang East Ctrl </option>
                <option>Geylang Serai </option>
                <option>Ghim Moh Link </option>
                <option>Ghim Moh Rd </option>
                <option>Gloucester Rd </option>
                <option>Guan Chuan St </option>
                <option>Haig Rd </option>
                <option>Havelock Rd </option>
                <option>Henderson Cres </option>
                <option>Henderson Rd </option>
                <option>Ho Ching Rd </option>
                <option>Holland Ave </option>
                <option>Holland Cl </option>
                <option>Holland Dr </option>
                <option>Hougang Ave 1 </option>
                <option>Hougang Ave 10 </option>
                <option>Hougang Ave 2 </option>
                <option>Hougang Ave 3 </option>
                <option>Hougang Ave 4 </option>
                <option>Hougang Ave 5 </option>
                <option>Hougang Ave 6 </option>
                <option>Hougang Ave 7 </option>
                <option>Hougang Ave 8 </option>
                <option>Hougang Ave 9 </option>
                <option>Hougang Ctrl </option>
                <option>Hougang St 11 </option>
                <option>Hougang St 21 </option>
                <option>Hougang St 22 </option>
                <option>Hougang St 31 </option>
                <option>Hougang St 32 </option>
                <option>Hougang St 51 </option>
                <option>Hougang St 52 </option>
                <option>Hougang St 61 </option>
                <option>Hougang St 91 </option>
                <option>Hougang St 92 </option>
                <option>Hoy Fatt Rd </option>
                <option>Indus Rd </option>
                <option>Jelapang Rd </option>
                <option>Jelebu Rd </option>
                <option>Jellicoe Rd </option>
                <option>Jln Bahagia </option>
                <option>Jln Batu </option>
                <option>Jln Berseh </option>
                <option>Jln Bt Ho Swee </option>
                <option>Jln Bt Merah </option>
                <option>Jln Damai </option>
                <option>Jln Dua </option>
                <option>Jln Dusun </option>
                <option>Jln Kayu </option>
                <option>Jln Klinik </option>
                <option>Jln Kukoh </option>
                <option>Jln Ma\'mor </option>
                <option>Jln Membina </option>
                <option>Jln Minyak </option>
                <option>Jln Rajah </option>
                <option>Jln Rumah Tinggi </option>
                <option>Jln Sultan </option>
                <option>Jln Teck Whye </option>
                <option>Jln Tenaga </option>
                <option>Jln Tenteram </option>
                <option>Jln Tiga </option>
                <option>Joo Chiat Rd </option>
                <option>Joo Seng Rd </option>
                <option>Jurong East Ave 1 </option>
                <option>Jurong East St 13 </option>
                <option>Jurong East St 21 </option>
                <option>Jurong East St 24 </option>
                <option>Jurong East St 31 </option>
                <option>Jurong East St 32 </option>
                <option>Jurong West Ave 1 </option>
                <option>Jurong West Ave 3 </option>
                <option>Jurong West Ave 5 </option>
                <option>Jurong West Ctrl 1 </option>
                <option>Jurong West Ctrl 3 </option>
                <option>Jurong West St 24 </option>
                <option>Jurong West St 25 </option>
                <option>Jurong West St 41 </option>
                <option>Jurong West St 42 </option>
                <option>Jurong West St 51 </option>
                <option>Jurong West St 52 </option>
                <option>Jurong West St 61 </option>
                <option>Jurong West St 62 </option>
                <option>Jurong West St 64 </option>
                <option>Jurong West St 65 </option>
                <option>Jurong West St 71 </option>
                <option>Jurong West St 72 </option>
                <option>Jurong West St 73 </option>
                <option>Jurong West St 74 </option>
                <option>Jurong West St 75 </option>
                <option>Jurong West St 81 </option>
                <option>Jurong West St 91 </option>
                <option>Jurong West St 92 </option>
                <option>Jurong West St 93 </option>
                <option>Kallang Bahru </option>
                <option>Kang Ching Rd </option>
                <option>Keat Hong Cl </option>
                <option>Keat Hong Link </option>
                <option>Kelantan Rd </option>
                <option>Kent Rd </option>
                <option>Kg Arang Rd </option>
                <option>Kg Kayu Rd </option>
                <option>Kim Cheng St </option>
                <option>Kim Keat Ave </option>
                <option>Kim Keat Link </option>
                <option>Kim Pong Rd </option>
                <option>Kim Tian Pl </option>
                <option>Kim Tian Rd </option>
                <option>King George\'s Ave </option>
                <option>Kitchener Rd </option>
                <option>Klang Lane </option>
                <option>Kreta Ayer Rd </option>
                <option>Lengkok Bahru </option>
                <option>Lengkong Tiga </option>
                <option>Lim Liak St </option>
                <option>Lompang Rd </option>
                <option>Lor Ah Soo </option>
                <option>Lor Lew Lian </option>
                <option>Lor Limau </option>
                <option>Lor 1 Toa Payoh </option>
                <option>Lor 1a Toa Payoh </option>
                <option>Lor 2 Toa Payoh </option>
                <option>Lor 3 Geylang </option>
                <option>Lor 3 Toa Payoh </option>
                <option>Lor 4 Toa Payoh </option>
                <option>Lor 5 Toa Payoh </option>
                <option>Lor 6 Toa Payoh </option>
                <option>Lor 7 Toa Payoh </option>
                <option>Lor 8 Toa Payoh </option>
                <option>Lower Delta Rd </option>
                <option>Macpherson Lane </option>
                <option>Marine Cres </option>
                <option>Marine Dr </option>
                <option>Marine Parade Ctrl </option>
                <option>Marine Ter </option>
                <option>Marsiling Cres </option>
                <option>Marsiling Dr </option>
                <option>Marsiling Lane </option>
                <option>Marsiling Rd </option>
                <option>Marsiling Rise </option>
                <option>Maude Rd </option>
                <option>Mcnair Rd </option>
                <option>Mei Ling St </option>
                <option>Merpati Rd </option>
                <option>Moh Guan Ter </option>
                <option>Montreal Dr </option>
                <option>Montreal Link </option>
                <option>Moulmein Rd </option>
                <option>New Mkt Rd </option>
                <option>New Upp Changi Rd </option>
                <option>Northshore Dr </option>
                <option>Nth Bridge Rd </option>
                <option>Old Airport Rd </option>
                <option>Owen Rd </option>
                <option>Pandan Gdns </option>
                <option>Pasir Ris Ave </option>
                <option>Pasir Ris Dr 1 </option>
                <option>Pasir Ris Dr 10 </option>
                <option>Pasir Ris Dr 3 </option>
                <option>Pasir Ris Dr 4 </option>
                <option>Pasir Ris Dr 6 </option>
                <option>Pasir Ris Hts </option>
                <option>Pasir Ris Rd </option>
                <option>Pasir Ris St 11 </option>
                <option>Pasir Ris St 12 </option>
                <option>Pasir Ris St 13 </option>
                <option>Pasir Ris St 21 </option>
                <option>Pasir Ris St 41 </option>
                <option>Pasir Ris St 51 </option>
                <option>Pasir Ris St 52 </option>
                <option>Pasir Ris St 53 </option>
                <option>Pasir Ris St 71 </option>
                <option>Pasir Ris St 72 </option>
                <option>Pasir Ris Ter </option>
                <option>Paya Lebar Way </option>
                <option>Pending Rd </option>
                <option>Petir Rd </option>
                <option>Pine Cl </option>
                <option>Pipit Rd </option>
                <option>Potong Pasir Ave 1 </option>
                <option>Potong Pasir Ave 2 </option>
                <option>Potong Pasir Ave 3 </option>
                <option>Punggol Ctrl </option>
                <option>Punggol Dr </option>
                <option>Punggol East </option>
                <option>Punggol Field </option>
                <option>Punggol Field Walk </option>
                <option>Punggol Pl </option>
                <option>Punggol Rd </option>
                <option>Punggol Walk </option>
                <option>Punggol Way </option>
                <option>Queen St </option>
                <option>Queen\'s Cl </option>
                <option>Queen\'s Rd </option>
                <option>Queensway </option>
                <option>Race Course Rd </option>
                <option>Redhill Cl </option>
                <option>Redhill Lane </option>
                <option>Redhill Rd </option>
                <option>Rivervale Cres </option>
                <option>Rivervale Dr </option>
                <option>Rivervale St </option>
                <option>Rivervale Walk </option>
                <option>Rowell Rd </option>
                <option>Sago Lane </option>
                <option>Saujana Rd </option>
                <option>Sector A Sin Ming Ind Est </option>
                <option>Segar Rd </option>
                <option>Selegie Rd </option>
                <option>Sembawang Cl </option>
                <option>Sembawang Cres </option>
                <option>Sembawang Dr </option>
                <option>Sembawang Vista </option>
                <option>Sembawang Way </option>
                <option>Seng Poh Rd </option>
                <option>Sengkang Ctrl </option>
                <option>Sengkang East Ave </option>
                <option>Sengkang East Rd </option>
                <option>Sengkang East Way </option>
                <option>Sengkang West Ave </option>
                <option>Sengkang West Rd </option>
                <option>Sengkang West Way </option>
                <option>Senja Link </option>
                <option>Senja Rd </option>
                <option>Serangoon Ave 1 </option>
                <option>Serangoon Ave 2 </option>
                <option>Serangoon Ave 3 </option>
                <option>Serangoon Ave 4 </option>
                <option>Serangoon Ctrl </option>
                <option>Serangoon Ctrl Dr </option>
                <option>Serangoon Nth Ave 1 </option>
                <option>Serangoon Nth Ave 2 </option>
                <option>Serangoon Nth Ave 3 </option>
                <option>Serangoon Nth Ave 4 </option>
                <option>Shunfu Rd </option>
                <option>Silat Ave </option>
                <option>Simei Lane </option>
                <option>Simei Rd </option>
                <option>Simei St 1 </option>
                <option>Simei St 2 </option>
                <option>Simei St 4 </option>
                <option>Simei St 5 </option>
                <option>Sims Ave </option>
                <option>Sims Dr </option>
                <option>Sims Pl </option>
                <option>Sin Ming Ave </option>
                <option>Sin Ming Rd </option>
                <option>Smith St </option>
                <option>Spooner Rd </option>
                <option>Spottiswoode Pk Rd </option>
                <option>St. George\'s Lane </option>
                <option>St. George\'s Rd </option>
                <option>Stirling Rd </option>
                <option>Strathmore Ave </option>
                <option>Sumang Lane </option>
                <option>Sumang Link </option>
                <option>Sumang Walk </option>
                <option>Tah Ching Rd </option>
                <option>Taman Ho Swee </option>
                <option>Tampines Ave 1 </option>
                <option>Tampines Ave 2 </option>
                <option>Tampines Ave 4 </option>
                <option>Tampines Ave 5 </option>
                <option>Tampines Ave 7 </option>
                <option>Tampines Ave 8 </option>
                <option>Tampines Ave 9 </option>
                <option>Tampines Ctrl 1 </option>
                <option>Tampines Ctrl 7 </option>
                <option>Tampines Ctrl 8 </option>
                <option>Tampines Nth Dr 1 </option>
                <option>Tampines St 11 </option>
                <option>Tampines St 12 </option>
                <option>Tampines St 21 </option>
                <option>Tampines St 22 </option>
                <option>Tampines St 23 </option>
                <option>Tampines St 24 </option>
                <option>Tampines St 32 </option>
                <option>Tampines St 33 </option>
                <option>Tampines St 34 </option>
                <option>Tampines St 41 </option>
                <option>Tampines St 42 </option>
                <option>Tampines St 43 </option>
                <option>Tampines St 44 </option>
                <option>Tampines St 45 </option>
                <option>Tampines St 61 </option>
                <option>Tampines St 71 </option>
                <option>Tampines St 72 </option>
                <option>Tampines St 81 </option>
                <option>Tampines St 82 </option>
                <option>Tampines St 83 </option>
                <option>Tampines St 84 </option>
                <option>Tampines St 86 </option>
                <option>Tampines St 91 </option>
                <option>Tanglin Halt Rd </option>
                <option>Tao Ching Rd </option>
                <option>Teban Gdns Rd </option>
                <option>Teck Whye Ave </option>
                <option>Teck Whye Cres </option>
                <option>Teck Whye Lane </option>
                <option>Telok Blangah Cres </option>
                <option>Telok Blangah Dr </option>
                <option>Telok Blangah Hts </option>
                <option>Telok Blangah Rise </option>
                <option>Telok Blangah St 31 </option>
                <option>Telok Blangah Way </option>
                <option>Tessensohn Rd </option>
                <option>Tg Pagar Plaza </option>
                <option>Thomson Rd </option>
                <option>Tiong Bahru Rd </option>
                <option>Tiong Poh Rd </option>
                <option>Toa Payoh Ctrl </option>
                <option>Toa Payoh East </option>
                <option>Toa Payoh Nth </option>
                <option>Toh Guan Rd </option>
                <option>Toh Yi Dr </option>
                <option>Towner Rd </option>
                <option>Ubi Ave 1 </option>
                <option>Upp Aljunied Lane </option>
                <option>Upp Boon Keng Rd </option>
                <option>Upp Cross St </option>
                <option>Upp Serangoon Cres </option>
                <option>Upp Serangoon Rd </option>
                <option>Upp Serangoon View </option>
                <option>Veerasamy Rd </option>
                <option>Waterloo St </option>
                <option>Wellington Circle </option>
                <option>West Coast Dr </option>
                <option>West Coast Rd </option>
                <option>Whampoa Dr </option>
                <option>Whampoa Rd </option>
                <option>Whampoa Sth </option>
                <option>Whampoa West </option>
                <option>Woodlands Ave 1 </option>
                <option>Woodlands Ave 3 </option>
                <option>Woodlands Ave 4 </option>
                <option>Woodlands Ave 5 </option>
                <option>Woodlands Ave 6 </option>
                <option>Woodlands Ave 9 </option>
                <option>Woodlands Circle </option>
                <option>Woodlands Cres </option>
                <option>Woodlands Dr 14 </option>
                <option>Woodlands Dr 16 </option>
                <option>Woodlands Dr 40 </option>
                <option>Woodlands Dr 42 </option>
                <option>Woodlands Dr 44 </option>
                <option>Woodlands Dr 50 </option>
                <option>Woodlands Dr 52 </option>
                <option>Woodlands Dr 53 </option>
                <option>Woodlands Dr 60 </option>
                <option>Woodlands Dr 62 </option>
                <option>Woodlands Dr 70 </option>
                <option>Woodlands Dr 71 </option>
                <option>Woodlands Dr 72 </option>
                <option>Woodlands Dr 73 </option>
                <option>Woodlands Dr 75 </option>
                <option>Woodlands Ring Rd </option>
                <option>Woodlands Rise </option>
                <option>Woodlands St 11 </option>
                <option>Woodlands St 13 </option>
                <option>Woodlands St 31 </option>
                <option>Woodlands St 32 </option>
                <option>Woodlands St 41 </option>
                <option>Woodlands St 81 </option>
                <option>Woodlands St 82 </option>
                <option>Woodlands St 83 </option>
                <option>Yishun Ave 1 </option>
                <option>Yishun Ave 11 </option>
                <option>Yishun Ave 2 </option>
                <option>Yishun Ave 3 </option>
                <option>Yishun Ave 4 </option>
                <option>Yishun Ave 5 </option>
                <option>Yishun Ave 6 </option>
                <option>Yishun Ave 7 </option>
                <option>Yishun Ave 9 </option>
                <option>Yishun Ctrl </option>
                <option>Yishun Ctrl 1 </option>
                <option>Yishun Ring Rd </option>
                <option>Yishun St 11 </option>
                <option>Yishun St 20 </option>
                <option>Yishun St 21 </option>
                <option>Yishun St 22 </option>
                <option>Yishun St 31 </option>
                <option>Yishun St 41 </option>
                <option>Yishun St 42 </option>
                <option>Yishun St 43 </option>
                <option>Yishun St 44 </option>
                <option>Yishun St 51 </option>
                <option>Yishun St 61 </option>
                <option>Yishun St 71 </option>
                <option>Yishun St 72 </option>
                <option>Yishun St 81 </option>
                <option>Yong Siak St </option>
                <option>York Hill </option>
                <option>Yuan Ching Rd </option>
                <option>Yung An Rd </option>
                <option>Yung Ho Rd </option>
                <option>Yung Kuang Rd </option>
                <option>Yung Loh Rd </option>
                <option>Yung Ping Rd </option>
                <option>Yung Sheng Rd </option>
                <option>Zion Rd </option>
            </datalist>
        </Fragment>
    )
}

export default StreeNameList
