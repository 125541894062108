import React, { Fragment } from 'react'
const Spinner = () => {
    return (
        <Fragment>
            <div class="mt-4 d-flex flex-column align-items-center justify-content-center text-primary">
                <div class="row">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="row mt-2">
                    <strong>Loading...</strong>
                </div>
            </div>
        </Fragment>)
}

export default Spinner
