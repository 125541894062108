import React, { Fragment } from 'react';
import Transactions from '../users/Transactions'
import Search from '../users/Search'
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import AdsBanner from '../layout/AdsBanner';

const Home = () =>
  <Fragment>
    <AdsBanner />
    <Search />
    <Transactions />
    <SmartBanner
      title={'HDB Resale Transactions'}
      position={'bottom'}
      daysHidden={0}
      daysReminder={0} />
  </Fragment>

export default Home;
