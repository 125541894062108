import React, { useReducer } from 'react'
import axios from 'axios'
import TransactionServiceContext from './TransactionServiceContext'
import TransactionServiceReducer from './TransactionServiceReducer'
import { SEARCH_TRANSACTIONS, SET_LOADING, CLEAR_USERS, GET_REPOS, GET_USER } from '../types'

let githubClientId;
let githubClientSecret;
let resourceId = 'f1765b54-a209-4718-8d38-a39237f502b3'

if (process.env.NODE_ENV !== 'production') {
    githubClientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    githubClientSecret = process.env.REACT_APP_GITHUB_CLIENT_SECRET;
} else {
    githubClientId = process.env.GITHUB_CLIENT_ID;
    githubClientSecret = process.env.GITHUB_CLIENT_SECRET;
}

const TransactionServiceState = props => {
    const initialState = {
        transactions: [],
        user: {},
        repos: [],
        loading: false
    }

    const [state, dispatch] = useReducer(TransactionServiceReducer, initialState);

    // Search Transactions
    const search = async(block, streetName, flatType, sort) => {
        setLoading();
        let query = {}
        if (block.length > 0) {
            query.block = block
        }

        if (streetName.length > 0) {
            query.street_name = streetName
        }

        if (flatType.length > 0) {
            query.flat_type = flatType
        }

        axios.get(
            `https://data.gov.sg/api/action/datastore_search?resource_id=${resourceId}&q=${JSON.stringify(query)}&sort=${sort}`
        ).then(function(res) {
            dispatch({
                type: SEARCH_TRANSACTIONS,
                transactions: res.data.result.records,
                success: true,
                error: false
            });
        }).catch(function(error) {
            // handle error
            console.log(error);
            dispatch({
                type: SEARCH_TRANSACTIONS,
                error: true
            })
        });
    };


    // Get USer
    const getUser = async userName => {
        setLoading();
        const res = await axios.get(
            `https://api.github.com/users/${userName}?client_id=${githubClientId}&client_secret=${githubClientSecret}`
        );
        dispatch({ type: GET_USER, payload: res.data });
    };

    // Get Repos
    const getUserRepos = async userName => {
        setLoading();
        const res = await axios.get(
            `https://api.github.com/users/${userName}/repos?per_page=5&sort=created:asc&client_id=${githubClientId}&client_secret=${githubClientSecret}`
        );

        dispatch({
            type: GET_REPOS,
            payload: res.data,
        });
    };


    // Clear Users
    const userClear = () => dispatch({ type: CLEAR_USERS });

    // Set Loading
    const setLoading = () => dispatch({ type: SET_LOADING })

    return <TransactionServiceContext.Provider
    value = {
            {
                transactions: state.transactions,
                user: state.user,
                repos: state.repos,
                loading: state.loading,
                success: state.success,
                error: state.error,
                search,
                userClear,
                getUser,
                getUserRepos
            }
        } >
        { props.children } <
        /TransactionServiceContext.Provider>
}


export default TransactionServiceState;