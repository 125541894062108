import React from 'react'
import PropTypes from 'prop-types'
import Logo from './logo192.png'

const Navbar = ({ icon, title }) => {
  return (
    <nav className="navbar navbar-dark bg-primary">
      <a className="navbar-brand" href="/">
        <img src="/logo512.png" width="30" height="30" className="d-inline-block align-top mr-2" alt="HDB Resale Transactions" />
        {title}
      </a>
    </nav>
  )
}

Navbar.defaultProps = {
  title: 'HDB Resale Trasactions',
  icon: Logo
}
Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
}


export default Navbar
