import React, { useState, useContext } from 'react'
import TransactionServiceContext from '../../context/transaction/TransactionServiceContext'
import StreetNameList from '../layout/StreetNameList'
import firebase from "firebase/app";
import "firebase/analytics";

const Search = () => {

  const [block, setBlock] = useState('');
  const [streetName, setStreetName] = useState('');
  const [flatType, setFlatType] = useState('');
  const [sort, setSort] = useState('month desc');

  const transactionServiceContext = useContext(TransactionServiceContext);

  const onBlockChange = e => {
    setBlock(e.target.value);
  }

  const onStreetNameChange = e => {
    setStreetName(e.target.value);
  }

  const onFlatTypeChange = e => {
    setFlatType(e.target.value);
  }

  const onSortChange = e => {
    setSort(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    transactionServiceContext.search(block, streetName, flatType, sort);
    firebase.analytics().logEvent('Search', {
      block: block,
      streetName: streetName,
      flatType: flatType,
      sort: sort
    });
  }

  return (
    <div className="mt-2">
      <form onSubmit={onSubmit}>
        <div className="form-row">
          <div className="col-3">
            <input className="form-control" type="text" name="text" placeholder="Block" value={block} onChange={onBlockChange} />
          </div>
          <div className="col">
            <input list="streetNames" className="form-control" type="text" name="text" placeholder="Street Name" value={streetName} onChange={onStreetNameChange} />
            <StreetNameList />
          </div>
          <div className="col-auto">
            <button className="btn btn-primary" type="submit">Search</button>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-auto">
            <select className="form-control" value={flatType} onChange={onFlatTypeChange}>
              <option value="" >Flat Type</option>
              <option value="1 ROOM">1 Room</option>
              <option value="2 ROOM">2 Room</option>
              <option value="3 ROOM">3 Room</option>
              <option value="4 ROOM">4 Room</option>
              <option value="5 ROOM">5 Room</option>
              <option value="EXECUTIVE">Executive</option>
              <option value="MULTI-GENERATION">Multi-Gen</option>
            </select>
          </div>
          <div className="col">
            <select className="form-control" value={sort} onChange={onSortChange}>
              <option value="month desc">Search Latest transaction</option>
              <option value="month">Search Oldest transaction</option>
              <option value="resale_price desc">Search Highest price</option>
              <option value="resale_price">Search Lowest price</option>
            </select>
          </div>
        </div>
      </form>
    </div >
  )

}

export default Search
