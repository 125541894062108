import React from 'react'

export default class AdsBanner extends React.Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        return (
            <div align="center">
                <ins className='adsbygoogle'
                    style={{ display: 'inline-block', width: '728px', height: '90px' }}
                    data-ad-client='ca-pub-4805579570320678'
                    data-ad-slot='4625979520' />
            </div>
        );
    }
}